<template>
  <div>
    <Panel>
      <template #header>
        <h1 class="text-5xl font-light">App Configuration</h1>
      </template>

      <json-editor-vue class="editor" v-model="json" />

    </Panel>

  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import JsonEditorVue from 'json-editor-vue3'

export default {
  components: { JsonEditorVue },
  setup () {

    const state = reactive({
      json: {}
    })

    return {
      ...toRefs(state)
    }
  },
}
</script>