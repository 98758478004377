import { ref, reactive } from 'vue'

import http from '../services/http'
// import { useWsckOn } from './useWsck'

export function useAppConfigOne (appId, id) {
  const isLoading = ref(true)
  const hasError = ref(null)
  const config = ref(null)

  http.core
  .get(`/director/configurations/${appId}/${id}`)
  .then(({data}) => {
    config.value = data
  })
  .catch(err => {
    hasError.value = err.response ? err.response.data : err
  })
  .finally(() => {
    isLoading.value = false
  })

  return {
    isLoading,
    hasError,
    config
  }
}

export function useAppConfig () {
  const isLoading = ref(true)
  const hasError = ref(null)

  const list = reactive([])
  const byId = reactive({})

  // useWsckOn('process', 'process.state:updated', function (data) {
  //   console.log('process.state:updated', data, byId)
  //   if (byId[data.id]) {
  //     byId[data.id].state = data.state
  //     byId[data.id].startedAt = data.startedAt
  //     byId[data.id].lastError = data.lastError
  //     byId[data.id].nbRestarts = data.nbRestarts
  //     byId[data.id].streamState = data.streamState
  //     byId[data.id].hasError = ''
  //   } else {
  //     byId[data.id] = data
  //     list.push(data)
  //   }
  // })


  http.core
  .get('/director/configurations')
  .then(({data}) => {
    data.list.forEach(one => {
      byId[one.id] = one
      list.push(one)
    })
  })
  .catch(err => {
    hasError.value = err.response ? err.response.data : err
  })
  .finally(() => {
    isLoading.value = false
  })

  return {
    isLoading,
    hasError,
    list
  }
}