<template>
  <div>
    <Panel>
      <template #header>
        <h1 class="text-5xl font-light">App Configuration</h1>
      </template>

      <Message v-if="!!hasError" severity="error" :closable="false">{{ hasError }}</Message>
      
      <DataTable 
        :value="list" 
        responsiveLayout="scroll" 
        :loading="isLoading" 
        stripedRows
      >
        <Column field="id" header="Name">
          <template #body="{ data }">
            <div class="flex">
              <div>
                <div>{{data.id}}</div>
                <div class="font-light text-sm">{{data.appId}}</div>
              </div>
            </div>
          </template>
        </Column>
        
        <Column field="action">
          <template #body="{ data }">
            <div class="flex justify-content-end">
              <Button 
                class="p-button-rounded p-button-outlined p-button-sm mx-2" 
                icon="pi pi-eye" 
                @click="view(data)"
              />              
              <Button 
                v-if="false"
                class="p-button-rounded p-button-outlined p-button-sm mx-2" 
                icon="pi pi-pencil" 
                @click="edit(data)"
              />              
            </div>
          </template>
        </Column>
      </DataTable>

    </Panel>

      <Dialog v-model:visible="showConfig" :style="{width: '50vw'}" modal maximizable>
        <template #header>
          <h3>{{currentId}}</h3>
        </template>

        <p>
          <pre>
            {{ JSON.stringify(currentJson, null, '  ') }}
          </pre>
        </p>
        <template #footer>
          <Button label="Close" @click="close" autofocus />
        </template>
    </Dialog>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useAppConfig } from '../../compositions/useAppConfig'

export default {
  setup () {
    const router = useRouter()
    const showConfig = ref(false)
    const currentId = ref('')
    const currentAppId = ref('')
    const currentJson = ref({})
    
    function view (config) {
      showConfig.value = true
      currentId.value = config.id
      currentAppId.value = config.appId
      currentJson.value = config.data
    }

    function close () {
      showConfig.value = false
      currentId.value = ''
      currentAppId.value = ''
      currentJson.value = {}
    }

    function edit (config) {
      router.push(`/app-config/${config.appId}/${config.id}`)
    }

    return {
      showConfig,
      currentId,
      currentAppId,
      currentJson,
      view,
      edit,
      close,
      ...useAppConfig() 
    }
  },
}
</script>